import React, { Fragment } from 'react'
import * as styles from './Containers.module.scss'
import SectionContainer from './SectionContainer'

const AngledContainer = ({ children, svgColor = 'gray', ...args }) => {
  // color: navy, dark navy, white, gray
  //type: fullWidth, contained
  return (
    <Fragment>
      <section className={`${styles.angled_container}`}>
        <SectionContainer {...args}>{children}</SectionContainer>
        <svg
          className={`${styles.angledContainer__svgBox} ${
            styles[`${svgColor}SVGWrap`]
          }`}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon className={styles.basesvg} points="30,100 0,25 0,100" />
          <polygon className={styles.basesvg} points="25,100 100,100 100, 20" />
          <polygon
            className={styles[`${svgColor}SVG`]}
            points="30,100 30,90 0,20 0,30"
          />
          <polygon
            className={styles[`${svgColor}SVG`]}
            points="30,90 30,100 100,30 100, 0"
          />
        </svg>
      </section>
    </Fragment>
  )
}

export default AngledContainer
